<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <div ref="themed" tabindex="-1" :data-theme="theme" class="themed">
    <component
      :is="'script'"
      v-if="structuredData"
      type="application/ld+json"
      v-html="structuredData"
    >
    </component>
    <VueAnnouncer data-cy="vue-announcer" />
    <router-link
      id="skiplink"
      ref="skiplink"
      :to="`#${skipLinkTarget}`"
      class="skiplink"
      tabindex="1"
      @click="followSkiplink(`#${skipLinkTarget}`)"
      @keydown.enter.space.prevent="followSkiplink(`#${skipLinkTarget}`)"
    >
      Direkt zum Inhalt springen
    </router-link>
    <component :is="layout" data-cy="component" :meta="meta">
      <router-view id="skipLinkTarget" :ref="skipLinkTarget" tabindex="-1" />
    </component>
    <ABTest v-if="$brand.isFonic()" name="faqSearch">
      <template #a>
        <LegacySolutionManager />
      </template>
      <template #b>
        <ModernSolutionManager />
      </template>
    </ABTest>
    <LegacySolutionManager v-else />
    <Chat />
    <Idle />
    <OutdatedBrowserWarning />
    <div id="modals"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ABTest from 'src/components/common/a_b_test/a_b_test.vue'

import { defineAsyncComponent, markRaw } from 'vue'
import('./stylesheets/${BRAND_CODE}/globals.scss')

export default {
  name: 'App',
  components: {
    Idle: defineAsyncComponent(() =>
      import('src/components/common/idle/idle.vue')
    ),
    ModernSolutionManager: defineAsyncComponent(() =>
      import('src/components/ai_poc/solution_manager.vue')
    ),
    LegacySolutionManager: defineAsyncComponent(() =>
      import('src/components/common/solution_manager/solution_manager.vue')
    ),
    Chat: defineAsyncComponent(() =>
      import('src/components/common/chat/chat_modal.vue')
    ),
    OutdatedBrowserWarning: defineAsyncComponent(() =>
      import(
        'src/components/common/outdated_browser_warning/outdated_browser_warning.vue'
      )
    ),
    ABTest
  },
  data() {
    return {
      initialLoad: true,
      descriptionFromBus: undefined,
      titleFromBus: undefined,
      skipLinkTarget: 'main',
      layout: undefined
    }
  },
  head() {
    return {
      title: this.title,
      link: [this.canonical],
      meta: [
        {
          name: 'description',
          content: this.description
        },
        {
          property: 'og:title',
          content: this.title
        },
        {
          property: 'og:description',
          content: this.description
        },
        {
          property: 'og:url',
          content: this.fullUrl
        },
        {
          property: 'og:image',
          content: this.ogImage
        },
        this.robots
      ]
    }
  },
  computed: {
    ...mapState({
      theme: state => state.settings.theme
    }),
    origin() {
      return `https://www.${this.$brand.code}.de`
    },
    meta() {
      return this.$route.meta || {}
    },
    title() {
      if (this.titleFromBus) {
        if (this.$route.path.includes('selfcare')) {
          return this.titleFromBus + ' (Mein ' + this.$brand.name + ')'
        }

        return this.titleFromBus
      }

      let title = ''

      if (this.$route.meta && this.$route.meta.title) {
        title = ' ' + this.$route.meta.title
        if (this.$route.meta.layout === 'selfcare') {
          title += ' (Mein ' + this.$brand.name + ')'
        }
      }

      return this.$brand.name + title
    },
    description() {
      if (this.descriptionFromBus) {
        return this.descriptionFromBus
      }

      if (this.$route.meta && this.$route.meta.description) {
        return this.$route.meta.description
      }

      return (
        this.$brand.name +
        ' - die günstige prepaid SIM Karte. Tarife für Handy, Smartphone, Laptop und Tablet. Zum telefonieren und für mobiles Internet. Das ist die Wahrheit!'
      )
    },
    robots() {
      if ('allowIndexing' in this.$route.meta) {
        return this.$route.meta.allowIndexing === true
          ? { name: 'robots', content: 'index, follow' }
          : { name: 'robots', content: 'noindex, nofollow' }
      } else {
        return { name: 'robots', content: null }
      }
    },
    ogImage() {
      return this.origin + this.$brand.openGraph.image
    },
    fullUrl() {
      return this.origin + this.$route.path
    },
    canonical() {
      let canonical = {
        id: 'canonicalLinkElement',
        rel: null,
        href: null
      }

      if (
        this.$brand.isFonic() &&
        'isCanonical' in this.$route.meta &&
        this.$route.meta.isCanonical
      ) {
        canonical.rel = 'canonical'
        canonical.href = this.fullUrl
      }

      return canonical
    },
    structuredData() {
      return JSON.stringify(this.$route.meta.structuredData)
    }
  },
  watch: {
    $route: function (to, from) {
      if (to.meta.layout !== from.meta.layout) {
        this.layout = markRaw(
          defineAsyncComponent(() =>
            import(`src/layouts/${to.meta.layout}.vue`)
          )
        )
      }

      this.$nextTick(function () {
        if (to.meta && to.meta.skipLinkToStage) {
          this.skipLinkTarget = 'stagemain'
        } else {
          this.skipLinkTarget = 'main'
        }

        if (!this.initialLoad) {
          if (!to.params.preserveFocus && !to.hash) {
            this.setRouteFocus()
          }
        } else {
          this.initialLoad = false
        }
      })
    }
  },
  created() {
    // reset store because modal state is not preserved on reload
    this.$store.commit('modal/reset')
    this.$store.dispatch('settings/initTheme')
    this.$bus.on('description', descr => {
      this.descriptionFromBus = descr
    })
    this.$bus.on('title', t => {
      this.titleFromBus = t
    })
    this.$bus.on('page-change', () => {
      this.descriptionFromBus = undefined
      this.titleFromBus = undefined
    })
  },
  mounted() {
    this.setRouteAnnouncement(this.title)
  },
  methods: {
    followSkiplink(hashbang) {
      this.$refs[this.skipLinkTarget].$el.focus()

      window.location.hash = hashbang
    },
    setRouteFocus() {
      this.$refs.themed.focus()
    },
    setRouteAnnouncement(pagetitle) {
      this.$announcer.set(`Aktuelle Seite: ${pagetitle}`, 'assertive')
    }
  }
}
</script>

<style lang="scss">
@import 'src/stylesheets/app';

.skiplink {
  display: inline-block;
  position: absolute;
  z-index: 20;
  background-color: var(--background-primary);
  cursor: pointer;
  margin: 52px 15px 15px;
  padding: 10px;
  font-weight: bold;

  @include breakpoint($from-desktop) {
    margin-top: 15px;
  }
}

.skiplink:not(:focus) {
  position: absolute;
  left: 20px;
  border: 0 none;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  white-space: nowrap;
  width: 1px;
}
</style>
